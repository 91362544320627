.ngx-datatable {
  .datatable-header {
    border-color: #BDBDBD;
    border-style: solid;
    border-width: 1px 0 1px 0;
    font-weight: bold;
    font-size: 16px;
    line-height: 31px;
    color: #4A4642;

    .datatable-header-inner,
    .datatable-header-center {
      height: inherit;
    }

    .datatable-header-cell {
      height: inherit;
      padding: 10px;

      .datatable-header-cell-template-wrap {
        display: flex;
        align-items: center;
        height: 100% !important;
      }
    }
  }
  .datatable-body {
      .datatable-scroll {
        width: 100% !important;

        .datatable-row-wrapper {
          border-bottom: 1px solid #BDBDBD;
          .datatable-group-header .empty-group-header{
            span {
              cursor: default;
            }
            .group-title {
              margin-left: 32px;
            }
              span::before {
                content: '' !important;
              }
          }
        }
    }

    .datatable-body-row {
      font-size: 16px;
      line-height: 22px;
      color: #4A4642;
      border-bottom: 1px solid #BDBDBD;

      >div:hover {
        background-color: rgba(22, 158, 22, 0.1);
      }

      .datatable-body-cell {
        display: flex;
        align-items: center;
        padding: 10px;

        //white-space: break-spaces !important;
        .datatable-body-cell-label span {
          // white-space: break-spaces !important;
        }
      }

      .datatable-body-cell:last-child {
        justify-content: end;
        padding: 0;
      }

      .datatable-body-cell:has(span.empty-subscriber-message) {
        overflow-x: visible;
      }

      .datatable-body-cell:has(span.datatable-body-cell-pre-wrap) {
        white-space: pre-wrap;
      }

      &:last-child {
        border-bottom: none;
      }
    }
  }

  .datatable-footer {
    .datatable-pager {
      .pager {
        display: flex !important;
        align-items: center;
        justify-content: flex-end;

        li, li a, li a i {
          width: 30px;
          height: 30px;
        }

        li a {
          display: flex !important;
          align-items: center;
          justify-content: center;
          padding-top: 0;
          text-decoration: none;
          width: 100%;
          height: 100%;

        }

        li a i {
          display: flex !important;
          align-items: center;
          justify-content: center;
          font-size: 22px;
          color: #4A4642;
        }

        li.disabled a i {
          color: #BDBDBD !important;
        }

        li.pages {
          margin: 0 3px;
          a {
            font-weight: bold;
            font-size: 16px;
            line-height: 19px;
            color: #BDBDBD;
            flex: none;
            order: 2;
            flex-grow: 0;

          }
        }

        li.active {
          background-color: green;
         a {
           color: white;
         }
        }
      }
    }
  }
}

