$mat-library: (
  50: #d6e7ff,
  100: #d6e7ff,
  200: #d6e7ff,
  300: #d6e7ff,
  400: #5e6f9e,
  500: #2c3d4e,
  600: #5e6f9e,
  700: #2c3d4e,
  800: #2c3d4e,
  900: #2c3d4e,
  A100: #d6e7ff,
  A200: #d6e7ff,
  A400: #5e6f9e,
  A700: #2c3d4e,
  contrast: (
    50: #d6e7ff,
    100: #d6e7ff,
    200: #d6e7ff,
    300: #d6e7ff,
    400: #5e6f9e,
    500: #fff,
    600: #5e6f9e,
    700: #2c3d4e,
    800: #2c3d4e,
    900: #2c3d4e,
    A100: #d6e7ff,
    A200: #d6e7ff,
    A400: #5e6f9e,
    A700: #2c3d4e
  )
);

$palette-white-solid: #ffffff;
$palette-black-solid: #000000;
$palette-green-solid: #169E16;
$palette-green-light: rgba(22, 158, 22, 0.1);
$palette-orange-solid: #E86F00;
$palette-grey-solid: #4A4642;
$palette-grey-medium: #969595;
$palette-grey-light: #C4C4C4;
$palette-blue-solid: #074AAD;