// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@use '@angular/material' as mat;
// Plus imports for other components in your app.
@import "./theme/form.scss";
@import "./theme/table.scss";
@import "./theme/palette.scss";

//@import '~@swimlane/ngx-datatable/index.css';
//@import '~@swimlane/ngx-datatable/themes/material.scss';
@import '~@swimlane/ngx-datatable/assets/icons.css';

// Include the common styles for Angular Material. We include this here so that you only
// have to load a single css file for Angular Material in your app.
// Be sure that you only ever include this mixin once!
@include mat.core();

// Define the palettes for your theme using the Material Design palettes available in palette.scss
// (imported above). For each palette, you can optionally specify a default, lighter, and darker
// hue. Available color palettes: https://material.io/design/color/
$library-primary: mat.define-palette($mat-library);
$library-accent: mat.define-palette($mat-library, A200, A100, A400);

// The warn palette is optional (defaults to red).
$library-warn: mat.define-palette(mat.$red-palette);

// Create the theme object. A theme consists of configurations for individual
// theming systems such as "color" or "typography".
$library-theme: mat.define-light-theme((
  color: (
    primary: $library-primary,
    accent: $library-accent,
    warn: $library-warn,
  )
));

// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
@include mat.all-component-themes($library-theme);


// Custom Theming for Angular Material
// For more information: https://material.angular.io/guide/theming
@import '~@angular/material/theming';
// Plus imports for other components in your app.
// Include theme styles for core and each component used in your app.
// Alternatively, you can import and @include the theme mixins for each component
// that you are using.
//@include angular-material-theme($library-theme);

//quill styles
@import '~quill/dist/quill.core.css';
@import '~quill/dist/quill.bubble.css';
@import '~quill/dist/quill.snow.css';

@import '/src/screen-sizes.scss';


// global variables 
:root{
  // button colors
  --green-main: #21AF21;
  --green-hover: #29BC29;
}


*, *::after, *::before {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: "Open Sans", "Roboto", "Helvetica Neue", sans-serif;
}

html, body {
  height: 100%;
}

.full-width {
  width: 100%;
}

.full-height {
  height: 100%;
}

.global-input {
  width: 100%;

  font-weight: 400;
  font-size: 16px;
  line-height: 125%;
  color: $palette-grey-solid;

  background: #FFFFFF;
  border: 1px solid $palette-grey-light;
  border-radius: 3px;
  padding: .65rem 1rem;

  @media screen and (max-width: $screen-mobile-l) {
    font-size: 1rem;
  }

  &::placeholder {
    font-weight: 400;
    font-size: 16px;
    line-height: 125%;
    color: $palette-grey-light;
  }

  &.ng-invalid.ng-touched {
    outline: none;
    border: 1px solid $palette-orange-solid;
    color: $palette-orange-solid;

    &::placeholder {
      color: $palette-orange-solid;
    }
  }
}


input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

input[type=number] {
  -moz-appearance: textfield;
}

.global-label {
  font-weight: 300;
  font-size: 13px;
  line-height: 123%;
  color: $palette-black-solid;
}

.global-main-cnt {
  width: 100%;
  max-width: 1280px;

  padding: 0 1rem;
  margin: auto;

  // @media screen and (max-width: $screen-mobile-l) {
  //   padding: 0 1rem;
  // }
}

.global-title {
  font-style: normal;
  font-weight: 500;
  font-size: 20px;
  line-height: 24px;
  color: #444444
}

.global-authors {
  font-size: 16px;
  font-style: normal;
  //font-weight: 400;
  //line-height: 28px;
  //letter-spacing: 0.5px;
  text-align: left;

  line-height: 22px;
  color: #969595;
}

.mat-tab-header .mat-tab-label {
  opacity: 1;
}

.mat-pseudo-checkbox-checked::after {
  top: 3px;
  left: 3px;
}

.mat-tooltip {
  font-size: 16px;
}

.global-select {
  border: 0.628571px solid #C4C4C4;
  border-radius: 3px;
  padding: 10px 10px;
  min-width: 100px;
  max-width: 250px;

  .mat-select-trigger {
    margin-left: 10px;
    text-overflow: ellipsis;
    overflow: hidden;
    white-space: nowrap;
    align-items: center;
    display: flex;
    height: 20px;

    .mat-select-value {
      max-width: unset;

      .mat-select-value-text {
        width: 100%;
      }
    }
  }
}

.mat-slide-toggle-label {
  user-select: auto !important;
}

.global-button {
  display: flex;
  align-items: center;
  justify-content: center;

  font-family: 'Rubik', sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;

  text-decoration: none;
  color: #FFFFFF;

  background: #169E16;
  border-radius: 6px;
  padding: 12px 24px;

  outline: none;
  border: none;
  cursor: pointer;
  box-shadow: 0 4px 10px rgba(47, 153, 212, 0.24);

  transition: all 0.25s ease-in-out;

  &.default {
    background: #D8D8D8;
    color: $palette-grey-solid;
    box-shadow: none;

    &:hover {
      background: $palette-grey-solid;
    }
  }
  &:hover {
    background: #29BC29;
    color: #fff;
  }
  &:disabled {
    background: #D8D8D8;
    box-shadow: none;
    cursor: unset;
    pointer-events: none;
  }
  &.disabled {
    background: #D8D8D8;
    box-shadow: none;
    cursor: unset;
    pointer-events: none;
  }

  mat-icon {
    margin-right: 4px;
    color: white;
  }
}

.global-spinner-cnt {
  position: absolute;
  top: 0; bottom: 0; right: 0; left: 0;
  z-index: 10;
  opacity: .7;
  background-color: white;
  mat-spinner {
    position: absolute !important;
    right: calc(50% - 25px);
    top: calc(50% - 25px);
    z-index: 11;
  }
}


.global-link-green {
  position: relative;
  cursor: pointer;
  text-decoration: underline;
  color: #169E16;
  font-size: 16px;
  font-weight: 700;
  text-align: center;

  &:hover {
    color: #169E16;
    text-decoration: unset;
  }
  //&:visited {
  //  color: #800080 !important;
  //}
}

.global-link {
  cursor: pointer;
  text-decoration: unset;
  color: unset;
  font-weight: 500;
  text-align: left;

  &:hover {
    color: black;
    text-decoration: underline;
  }
  &:visited {
    color: unset;
  }
}


.green {
  background-color: $palette-green-solid;
}

.green-color {
  color: $palette-green-solid;
}

.orange {
  color: $palette-orange-solid;
}

.grey {
  background-color: #C4C4C4;
}

mat-card {
  box-shadow: 1.03103px 4.12414px 15px rgba(130, 130, 130, 0.18) !important;
  padding: 20px !important;
}

.mat-select-panel-wrap  {
  ::-webkit-scrollbar {
    width: 14px;
  }

  ::-webkit-scrollbar-track {
    box-shadow: inset 14px 0 5px #E9ECEE;
    border-radius: 10px;
  }

  ::-webkit-scrollbar-thumb {
    background: #C6CBD3;
    border-radius: 10px;
  }
}

.global-mat-form-field {
  display: flex;
  flex-direction: column;
  width: 100%;
  //max-width: 490px;
  height: 44px;
  padding: 0 8px 0 12px;
  background-color: white;
  border: 0.6px solid #C4C4C4;
  border-radius: 5px;

  .mat-form-field-suffix {
    top: 5px;

    .mat-icon {
      color: #C4C4C4;
    }
  }

  .mat-form-field-underline {
    display: none;
  }

  .mat-form-field-infix {
    border-top: 6px solid transparent;
  }

}

.global-text-area {
  width: 100%;
  border: 0.628571px solid #C4C4C4;
  border-radius: 3px;
}

.global-button-grey {
  display: flex;
  align-items: center;
  background-color: #C4C4C4;
  border: none;
  padding: 6px 24px 6px 20px;
  cursor: pointer;
  justify-content: center;

  span {
    color: white;
    font-weight: 500;
    font-size: 14px;
  }

  mat-icon {
    margin-right: 4px;
    color: white;
    font-size: 22px;
  }
}


.global-icon {
  &.active {
    color: #169e16;
  }

  &.inactive {
    color: #C4C4C4;
  }
}

.global-btn {
  min-width: 250px !important;
  height: 55px !important;
  border-radius: 3px !important;

  span {
    font-weight: bold;
    font-size: 16px;
    line-height: 19px;
    color: #FFFFFF;
  }
}

.primary {
  background: #169E16;
}

.secondary {
  background: #C4C4C4;
}

.global-table-message-container {
  margin-top: 20px;
  font-size: 18px;
  color: $palette-grey-medium;
  text-align: center;
}

// Material styles overwriting;
.cdk-overlay-dark-backdrop {
  background: rgba(0, 0, 0, 0.05);
}


.mat-input-element {
  height: 100%;
}

mat-dialog-container.mat-dialog-container {
  padding: 2rem 3rem;

  background: #FFFFFF;
  border: 1px solid #169E16;
  border-radius: 40px;

  box-shadow:
    0 2.1px 5.1px rgba(0, 0, 0, 0.012),
    0 7.1px 17.2px rgba(0, 0, 0, 0.018),
    0 32px 77px rgba(0, 0, 0, 0.03)
  ;

  h4 {
    text-align: center;
    font-weight: 400;

    margin-bottom: 1.5rem;
  }

  .mat-dialog-content,
  .mat-dialog-actions {
    margin: 0;
    padding: 0;
    min-height: auto;
  }
  .mat-dialog-actions {
    margin-top: 1.5rem;

    display: flex;
    justify-content: space-between;
    align-items: flex-end;

    .buttons {
      display: flex;
      margin-left: auto;
      gap: 1rem;
    }
  }
}

.global-copy-code-snackbar-position {

}
// --- ---

.global-flex-1 {
  flex: 1;
}

.global-flex-row-start-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: flex-start;
}

.global-flex-row-end-end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: flex-end;
}

.global-flex-row-space-between-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: space-between;
}

.global-flex-row-space-between-end {
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  justify-content: space-between;
}

.global-flex-row-space-between-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
}

.global-flex-row-space-between-baseline {
  display: flex;
  flex-direction: row;
  align-items: baseline;
  justify-content: space-between;
}

.global-flex-row-start-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-start;
}

.global-flex-row-center-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
}

.global-flex-row-center-start {
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  justify-content: center;
}

.global-flex-row-end-center {
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
}

.global-flex-column-start-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
}

.global-flex-column-start-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: flex-start;
}

.global-flex-column-space-between-start {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: space-between;
}

.global-flex-column-space-between-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.global-flex-column-center-center {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}
